/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import useMediaQuery from "@restart/hooks/cjs/useMediaQuery";
import MultiCarousel from "react-multi-carousel";
import { BiChevronLeft } from "@react-icons/all-files/bi/BiChevronLeft";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight";
import { classNames } from "repoV2/utils/common/render/classNames";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import { SECTION_IDS, useEventCategorisationKeywords } from "@Utils";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import { IHost } from "@Interfaces";
import { Keywords, LoadingSpinner, Navbar } from "@Modules/common";
import { ICategorisedEventList } from "@Modules/hostPage/CategorisedEventList/ICategorisedEventList";
import { ITemplate } from "@Templates/ITemplate";
import { CategorisedEventList } from "@Modules/hostPage";
import { FooterSection } from "repoV2/features/CreatorPages/modules/FooterSection";
import { BrandsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/BrandsList";
import { MediaList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/MediaList";
import style from "./classic.module.scss";
import {
    GalleryPlaceholder,
    EventCard,
    HostInfoPanel,
    GalleryCard,
    TestimonialSection,
    TeamSection,
} from "../components";
import { IEventCard } from "../components/EventCard/EventCard";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";

const ButtonGroup = ({
    next,
    previous,
    postAnalyticalEvent,
    isCarouselNeeded = true,
}: any): JSX.Element | null =>
    isCarouselNeeded ? (
        <div className={style.arrowGroup}>
            <div
                className={`${style.arrowStyleCarousal} ${style.leftAligned}`}
                onClick={() => {
                    previous();
                    postAnalyticalEvent({
                        event_name: "scrollLeft",
                        action: "scroll",
                        ui_component: "Button",
                    });
                }}
            >
                <BiChevronLeft />
            </div>
            <div
                className={`${style.arrowStyleCarousal} ${style.rightAligned}`}
                onClick={() => {
                    next();
                    postAnalyticalEvent({
                        event_name: "scrollRight",
                        action: "scroll",
                        ui_component: "Button",
                    });
                }}
            >
                <BiChevronRight />
            </div>
        </div>
    ) : null;

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1440 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1439, min: 769 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};

const Classic = ({
    handleSocialShare,
    handlePreviewPopup,
    postAnalyticalEvent,
    preview,

    // common to be used by sub-components in TemplateRoots
    hostData,
    navbarProps,
    aboutSectionProps,
    eventSectionsProps,
    testimonialsSectionProps,
    gallerySectionProps,
    teamSectionProps,
    footerSectionProps,
}: ITemplate.IProps): JSX.Element => {
    const {
        eventListings,
        sectionTitle: eventSectionsTitle,
        onEventListingClick,
        allKeywords,
        allEventCategories,
        categoriseType,
        onShareClick: onEventShareClick,
        onHeartClick: onEventHeartClick,
        showSkuLabelAsTag,
        template,
    } = eventSectionsProps;

    const {
        hideComponent: hideGallerySection,
        title: gallerySectionTitle,
        galleryData,
        pauseGalleryAutoplay,
        onGalleryItemClick,
        onGalleryItemShareClick,
    } = gallerySectionProps;

    // Whether display viewport is desktop
    const isDesktop: boolean = useMediaQuery(
        `(min-width: ${responsive.desktop.breakpoint.min}px)`
    );

    // Whether display viewport is tablet (assumes isDesktop = false)
    const isTablet: boolean = useMediaQuery(
        `(min-width: ${responsive.tablet.breakpoint.min}px)`
    );

    const device: keyof typeof responsive =
        (isDesktop && "desktop") || (isTablet && "tablet") || "mobile";

    // Carousel will only be needed if the total length of the gallery is greater than the number of it
    const isCarouselNeeded: boolean =
        galleryData.length > responsive[device].items;

    const getEventCardProps = (
        item: ITemplate.IEventListingData
    ): IEventCard.IProps => ({
        metadata: item?.metadata,
        updated_price: item.updated_price,
        currency: item?.currency,
        eventType: item.type,
        title: item?.title,
        image: item.cover_image,
        shortDescription: item.short_description,
        showSkuLabelAsTag,
        onHeartClick: () => {
            onEventHeartClick("id" in item ? item.id : undefined);
        },
        onShareClick: () => {
            onEventShareClick(item);
        },
        onClickBook: () => {
            onEventListingClick(item);
        },
        listingTypeTitle:
            ("sku_title" in item && item.sku_title) ||
            ("listingTypeTitle" in item && item.listingTypeTitle) ||
            "",
        isPlan: "isPlan" in item && item.isPlan,
        categoriseType,
        ctaText: item.ctaText,
    });

    const EventSectionsContainer: ICategorisedEventList["EventSectionsContainer"] =
        ({ children }) => {
            return (
                <>
                    <a id={SECTION_IDS.eventSections} />
                    {children}
                </>
            );
        };

    // TODO: Move to a separate component
    const EventSection: ICategorisedEventList["EventSection"] = ({
        eventListProp,
        index,
        category,
        showKeywords,
    }) => {
        const {
            filteredEventList,
            loading,
            keywords,
            onKeywordClick,
            selectedKeyword,
        } = useEventCategorisationKeywords(eventListProp, allKeywords);

        return (
            <div key={index} className={style.eventContainer}>
                {isDefined(index) ? (
                    <a
                        id={SECTION_IDS.eventSection(index!)}
                        className={style.anchorEventSection}
                    />
                ) : null}
                <b className={style.eventHeader}>{category}</b>
                {showKeywords ? (
                    <Keywords
                        keywords={keywords}
                        selectedKeyword={selectedKeyword}
                        keywordClick={onKeywordClick}
                        template={template}
                    />
                ) : null}
                {loading ? (
                    <div className={style.loadingSpinner}>
                        <LoadingSpinner small />
                    </div>
                ) : (
                    <div
                        className={`${
                            isDesktop ? style.cardContainerSection : ""
                        } justify-content-center align-items-center`}
                    >
                        {filteredEventList.map(item => (
                            <EventCard
                                key={`${item.uuid}${
                                    "plan_uuid" in item
                                        ? `-${item.plan_uuid}`
                                        : ""
                                }`}
                                {...getEventCardProps(item)}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={style.root}>
            <Navbar {...navbarProps} />
            <div className={style.hostIntro}>
                <HostInfoPanel {...aboutSectionProps} />
            </div>
            {eventListings.length > 0 ? (
                <CategorisedEventList
                    eventSectionsTitle={eventSectionsTitle}
                    EventSectionsContainer={EventSectionsContainer}
                    EventSection={EventSection}
                    allCategories={allEventCategories}
                    eventListings={eventListings}
                    categoriseType={categoriseType}
                />
            ) : null}
            <TeamSection {...teamSectionProps} />
            <TestimonialSection {...testimonialsSectionProps} />

            <BrandsList titleClass={`${style.sectionTitle}`} />
            <MediaList
                titleClass={`${style.sectionTitle}`}
                dotDesign="horizontal bar"
            />

            {(!hideGallerySection && galleryData.length > 0 && (
                <div
                    className={style.galleryContainer}
                    id={SECTION_IDS.gallery}
                >
                    <a
                        id={SECTION_IDS.gallery}
                        className={style.anchorGallery}
                    />
                    <div className={style.galleryHeader}>
                        {gallerySectionTitle}
                    </div>
                    <div className={style.galleryOuterDiv}>
                        <MultiCarousel
                            responsive={responsive}
                            ssr
                            arrows={false}
                            infinite
                            className={classNames(
                                style.galleryC,
                                isCarouselNeeded && style.carouselEnabled
                            )}
                            deviceType={device}
                            autoPlay={!pauseGalleryAutoplay && isCarouselNeeded}
                            swipeable={isCarouselNeeded}
                            draggable={isCarouselNeeded}
                            customButtonGroup={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <ButtonGroup
                                    isCarouselNeeded={isCarouselNeeded}
                                    postAnalyticalEvent={postAnalyticalEvent}
                                />
                            }
                        >
                            {galleryData.map((item: IHost.IGalleryItem) => (
                                <GalleryCard
                                    key={item.uuid}
                                    source={item.media}
                                    type={item.type}
                                    title={item.caption}
                                    onClick={() => {
                                        onGalleryItemClick(item);
                                    }}
                                    onClickShare={() => {
                                        onGalleryItemShareClick(item);
                                    }}
                                />
                            ))}
                        </MultiCarousel>
                    </div>
                </div>
            )) ||
                (preview && (
                    <>
                        <a
                            id={SECTION_IDS.gallery}
                            className={style.anchorGallery}
                        />
                        <div className={style.galleryHeader}>
                            {gallerySectionTitle}
                        </div>
                        <div className={style.previewSection}>
                            {[1, 2, 3].map(item => (
                                <GalleryPlaceholder
                                    key={item}
                                    item={item}
                                    onClick={() => handlePreviewPopup()}
                                />
                            ))}
                        </div>
                    </>
                )) ||
                null}
            <CustomCodeSection
                position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
            />
            <FooterSection {...footerSectionProps} />
            <div className={style.stickyContainer}>
                <button
                    type="button"
                    className={style.spreadWordButton}
                    onClick={() => {
                        if (hostData.custom_bottom_button_cta) {
                            window.location.href =
                                hostData.custom_bottom_button_cta;
                        } else {
                            handleSocialShare("spread");
                        }
                    }}
                >
                    {hostData?.custom_bottom_button_text || "SPREAD THE WORD"}
                </button>
            </div>
        </div>
    );
};

export default Classic;
